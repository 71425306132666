import React,{useState,useEffect} from 'react'
import './Recharge.css'
import store from '../../../mobx/store';
import { autorun, runInAction } from 'mobx';
import axios from 'axios';
//npm install react-transition-group过渡动画1/2
import { TransitionGroup, CSSTransition } from "react-transition-group";
import '../../../transitions/transitions.css'; // 存放过渡动画的样式
export default function Recharge() {
    const [rechargeisshow, setrechargeisshow] = useState(false);//控制用户充值页显示和隐藏
    const [rechargetoisshow, setrechargetoisshow] = useState(false);//用于控制点击topbar后显示用户登录式样还是注册登录等式样
    const [slideTo, setSlideTo] = useState('to-left');//控制模型选择的滑块
    const [vipaddisshow, setvipaddisshow] = useState(false);//控制显示会员还是加量包
    const [rechargeuservip, setrechargeuservip] = useState([
      {id:0,spanone:'月度高级会员',spantwo:'￥19.9元每月',spanthree:'每日仅0.66元',spanfour:'AI无限量对话',spanfive:'赠送AI算力200积分',spansix:'全站功能解锁',spanseven:'4k超清画质',spaneight:'PC+手机+iPad多端通用'},
      {id:1,spanone:'季度高级会员',spantwo:'￥19.9元每季',spanthree:'每日仅0.66元',spanfour:'AI无限量对话',spanfive:'赠送AI算力200积分',spansix:'全站功能解锁',spanseven:'4k超清画质',spaneight:'PC+手机+iPad多端通用'},
      {id:2,spanone:'年度高级会员',spantwo:'￥19.9元每年',spanthree:'每日仅0.66元',spanfour:'AI无限量对话',spanfive:'赠送AI算力200积分',spansix:'全站功能解锁',spanseven:'4k超清画质',spaneight:'PC+手机+iPad多端通用'},
    ]);
    const [rechargeuseradd, setrechargeuseradd] = useState([
      {id:0,spanone:'积分包236积分',spantwo:'￥29.9元/236积分',spanthree:'每积分仅0.11元',spanfour:'充值AI通用积分236积分',spanfive:'全站功能通用',spansix:'充值积分永不过期',spanseven:'4k超清画质',spaneight:'PC+手机+iPad多端通用'},
      {id:1,spanone:'积分包236积分',spantwo:'￥29.9元/236积分',spanthree:'每积分仅0.11元',spanfour:'充值AI通用积分236积分',spanfive:'全站功能通用',spansix:'充值积分永不过期',spanseven:'4k超清画质',spaneight:'PC+手机+iPad多端通用'},
      {id:2,spanone:'积分包236积分',spantwo:'￥29.9元/236积分',spanthree:'每积分仅0.11元',spanfour:'充值AI通用积分236积分',spanfive:'全站功能通用',spansix:'充值积分永不过期',spanseven:'4k超清画质',spaneight:'PC+手机+iPad多端通用'},
    ]);
    useEffect(() => {
        var unsubscribe=autorun(()=>{
            setrechargeisshow(store.loginisshow)
            setrechargetoisshow(store.userisshow);
            // console.log(store.loginisshow);
            // console.log(store.userisshow);
          })
        return () => {
            unsubscribe()
        };
    }, []);
    const rechargeclosedown=()=>{
      store.userisshowdown(2)
    }
    //点了模型选择
    const modeldown=(index)=>{
      if(index==0){
          setvipaddisshow(false)
          setSlideTo('to-left')
      }else if(index==1){
        setvipaddisshow(true)
          setSlideTo('to-right')
      }
  }
  //点了经常文的问题
  const rechargeissuedown=()=>{
     store.userisshowdown(4)
  }
    const recharge=async()=>{
       //发请求
    // const params2 = new URLSearchParams();
    // // params2.append('imgid',imglist[index].id );
    // axios.defaults.headers["Authorization"] = localStorage.getItem('aiwebtoken')
    // const response =await axios.post('http://127.0.0.1:3001/imgdelete',params2)
    // console.log(response.data);
    }
  return (
    <div  style={{
        display: rechargetoisshow ==3 ? 'block' : 'none',
      }}>
    {/* 登录主体 */}
     {/* 过渡动画1/2 */}
     <CSSTransition
        in={rechargeisshow}              // 控制组件是否显示
        timeout={300}          // 动画时长
        classNames="fade"      // CSS 类名前缀
        unmountOnExit          // 退出时从 DOM 中移除
      >
    <div className='recharge'>
        <div className='loginbox'>
             <div className='rechargeclose' onClick={rechargeclosedown}/>
             <div className='onebox'>
                  <span>成为尊贵会员（多端通用）</span>
             </div>
             <div className='twobox'>
              {/* 下面这行时滑块 */}
             <div className={`slider ${slideTo}`}></div>
                <div className='userselect' onClick={modeldown.bind(this,0)}>
                     <span>vip开通</span>
                </div>
                <div className='userselect' onClick={modeldown.bind(this,1)}>
                     <span>算力包</span>
                </div>
             </div>
             <div className='threebox'>
              {!vipaddisshow?rechargeuservip.map((item,index)=>(
               <div className='threepaddingboxin' key={index}>
               <div className='threeboxin'>
                <div className='one'>
                   <span>{item.spanone}</span>
                </div>
                <div className='two'>
                   <span>{item.spantwo}</span>
                </div>
                <div className='three'>
                   <span>{item.spanthree}</span>
                </div>
                <div className='four'>
                   <span>立即开通</span>
                </div>
                <div className='five'>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfour}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfive}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spansix}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanseven}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spaneight}</span>
                   </div>
                </div>
                <div className='six' onClick={rechargeissuedown}>
                    <span>经常问的问题</span>
                </div>
               </div>
               </div>
              ))
              :
              rechargeuseradd.map((item,index)=>(
               <div className='threepaddingboxin'>
               <div className='threeboxin'>
                <div className='one'>
                   <span>{item.spanone}</span>
                </div>
                <div className='two'>
                   <span>{item.spantwo}</span>
                </div>
                <div className='three'>
                   <span>{item.spanthree}</span>
                </div>
                <div className='four'>
                   <span>立即开通</span>
                </div>
                <div className='five'>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfour}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfive}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spansix}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanseven}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spaneight}</span>
                   </div>
                </div>
                <div className='six' onClick={rechargeissuedown}>
                    <span>经常问的问题</span>
                </div>
               </div>
               </div>
              ))
              }
             </div>
        </div>
    </div>
    </CSSTransition>
     </div>
  )
}
